$basepath: '/';

$breakpoints: (
    s: 375px,
    sm: 415px,
    m: 768px,
    tabletL: 1024px,
    l: 1280px,
    xl: 1440px,
    xxl: 1680px,
);

$maxwidths: (
    s: 345px,
    m: 720px,
    tabletL: 920px,
    l: 1140px,
    xl: 1260px,
    xxl: 1440px,
    content: 725px,
    content-l: 640px,
    text: 628px,
);

$content: map-get($maxwidths, content);
$text: map-get($maxwidths, text);

$colorBlack: #000;
$colorBlack08: rgba($colorBlack, 0.08);
$colorBlack10: rgba($colorBlack, 0.1);
$colorBlack12: rgba($colorBlack, 0.12);
$colorBlack14: rgba($colorBlack, 0.14);
$colorBlack16: rgba($colorBlack, 0.16);
$colorBlack20: rgba($colorBlack, 0.2);
$colorBlack24: rgba($colorBlack, 0.24);
$colorBlack40: rgba($colorBlack, 0.4);
$colorBlack60: rgba($colorBlack, 0.6);
$colorBlack70: rgba($colorBlack, 0.7);
$colorBlack90: rgba($colorBlack, 0.9);

$colorBlue: #005293;
$colorDawnBlue: #1a3050;
$colorDawnBlue08: mix(black, $colorDawnBlue, 8%);
$colorDawnBlue12: mix(black, $colorDawnBlue, 12%);
$colorOutlineBlue: #5ca4ff;

$colorYellow: #fecb00;
$colorYellow08: mix(black, $colorYellow, 8%);
$colorYellow12: mix(black, $colorYellow, 12%);
$colorPaleYellow: #ffd481;
$colorPaleYellow08: mix(black, $colorPaleYellow, 8%);
$colorPaleYellow12: mix(black, $colorPaleYellow, 12%);

$colorGrey050: #fafafa;
$colorGrey100: #f5f5f5;
$colorGrey200: #eee;
$colorGrey300: #e0e0e0;
$colorGrey400: #bdbdbd;
$colorGrey500: #9e9e9e;
$colorGrey600: #757575;
$colorGrey700: #616161;
$colorGrey800: #424242;
$colorGrey900: #212121;

$cardBorderRadius: 2px;

$boxShadowWhite: rgba($colorBlack, 0.12) 0 4px 6px;
$boxShadowDropup: rgba($colorBlack, 0.16) 0 -2px 8px;
$boxShadowStyle: rgba($colorBlack, 0.12) 0 2px 4px;
$boxShadowCard: 0 2px 8px 0 rgba($colorBlack, 0.16);

$gutter: 160px;
$gutterMd: 80px;
$gutterMobile: 48px; // Used for media query below md

$fontSwedenSans: 'SwedenSans', Lucida Console, Monaco, monospace;
$fontSourceSansPro: 'SourceSans3', Helvetica, Arial, sans-serif;

// Transitions
$duration: 0.3s;
$ease: ease-in-out;
$transition: $duration $ease;

// Z-indexes
$z-behind: -1;
$z-front: 100;
$z-dimmed: 1001;
$z-above-dimmed: 1002;
$z-cookiebar: 1200;

@import 'mixins';
@import 'extensions';
