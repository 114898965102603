@mixin media($key) {
    @if $key == 'd' {
        @content;
    }
    @elseif map-has-key($breakpoints, $key) {
        @media (min-width: map-get($breakpoints, $key)) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin media-max($key) {
    @if $key == 'd' {
        @content;
    }
    @elseif map-has-key($breakpoints, $key) {
        @media (max-width: #{map-get($breakpoints, $key) - 1px}) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin edge-only() {
    @supports (display: -ms-grid) {
        @content;
    }
}

@mixin ie-9-plus() {
    @media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
        & {
            @content;
        }
    }
}
