%container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 16px;

    @include media(m) {
        max-width: (map-get($maxwidths, m));
        padding: 0;
    }

    @include media(tabletL) {
        max-width: (map-get($maxwidths, tabletL));
        padding: 0;
    }

    @include media(l) {
        max-width: (map-get($maxwidths, l));
        padding: 0;
    }

    @include media(xl) {
        max-width: (map-get($maxwidths, xl));
        padding: 0;
    }
}

%container-progress {
    max-width: (map-get($breakpoints, s));
    margin-left: auto;
    margin-right: auto;
}

%container-text {
    max-width: (map-get($maxwidths, content));
    margin-left: auto;
    margin-right: auto;

    padding: 0 16px;

    @include media(m) {
        padding: 0;
    }

    @include media(l) {
        transform: translateX(-94px);
        max-width: (map-get($maxwidths, content-l));
    }

    @include media(xl) {
        transform: translateX(-52px);
        max-width: (map-get($maxwidths, content));
    }
}

%h1 {
    font-size: 3rem;
    font-family: $fontSwedenSans;
    font-weight: bold;
    line-height: (40/30);

    @include media(m) {
        font-size: 3.6rem;
        line-height: (48/36);
    }

    @include media(l) {
        font-size: 4.4rem;
        line-height: (56/44);
    }

    @include media(xl) {
        font-size: 5.6rem;
        line-height: (64/56);
    }
}

%h2 {
    font-size: 2.4rem;
    font-family: $fontSwedenSans;
    font-weight: bold;
    line-height: (32/24);

    @include media(m) {
        font-size: 2.6rem;
        line-height: (32/26);
    }

    @include media(l) {
        font-size: 3rem;
        line-height: (40/30);
    }

    @include media(xl) {
        font-size: 3.6rem;
        line-height: (48/36);
    }
}

%h3 {
    font-size: 2rem;
    font-family: $fontSwedenSans;
    font-weight: bold;
    line-height: (24/20);

    @include media(l) {
        font-size: 2.2rem;
        line-height: (32/22);
    }

    @include media(xl) {
        font-size: 2.4rem;
        line-height: (32/24);
    }
}

%h4 {
    font-size: 1.8rem;
    font-family: $fontSwedenSans;
    font-weight: bold;
    line-height: (24/18);
    letter-spacing: 0.8px;

    @include media(xxl) {
        font-size: 2rem;
        line-height: (24/20);
    }
}

%h5 {
    font-size: 1.6rem;
    font-family: $fontSwedenSans;
    font-weight: bold;
    line-height: (20/16);
}

%navigation-titles {
    font-size: 1.6rem;
    font-family: $fontSourceSansPro;
    font-weight: 600;
    line-height: (19/16);
    letter-spacing: (0.4rem/10);

    @include media(m) {
        font-size: 1.6rem;
        line-height: (24/16);
    }
}

%navigation-sub-titles {
    font-size: 1.6rem;
    font-family: $fontSourceSansPro;
    line-height: (20/16);
    letter-spacing: (0.4rem/10);
}

%small-labels {
    font-size: 1.4rem;
    font-family: $fontSourceSansPro;
    line-height: (20/14);
    letter-spacing: (0.8rem/10);
}

%small-labels-semibold {
    font-size: 1.4rem;
    font-family: $fontSourceSansPro;
    font-weight: 600;
    line-height: (20/14);
    letter-spacing: (0.8rem/10);
}

%img-caption {
    font-size: 1.2rem;
    font-family: $fontSourceSansPro;
    line-height: (20/12);
    letter-spacing: (0.4rem/10);
}

%preamble {
    font-size: 2rem;
    font-family: $fontSourceSansPro;
    letter-spacing: (0.4rem/10);
    line-height: (32/20);
    color: $colorBlack;
}

%rich-preamble {
    p:not(:last-child) {
        margin-bottom: 12px;
    }

    a {
        color: $colorBlack;
        text-decoration: none;
        border-bottom: 1px solid #000;

        // Add external link styling if href containes visitsweden
        &:not([href*='visitsweden']),
        &[href*='corporate.visitsweden'],
        &[href*='traveltrade.visitsweden'] {
            line-height: 1.1;

            &::after {
                content: '';
                display: inline-block;
                min-width: 16px;
                min-height: 16px;
                margin-left: 3px;
                margin-right: 1px;
                background-image: url(#{$basepath}svg/icon-external-link.svg);
                background-repeat: no-repeat;
                background-size: contain;
                position: relative;
                bottom: -1.5px;
            }
        }
        // Remove external link styling if href starts with #
        &[href^='\#'] {
            padding-right: 0;

            &::after {
                display: none;
            }
        }
    }
}

%body-copy {
    font-size: 1.8rem;
    font-family: $fontSourceSansPro;
    letter-spacing: (0.2rem/10);
    // line-height: (32/18);
    line-height: 1.5; // this is overridden due to complaints about too big line height
    color: $colorBlack70;
}

%standard-link {
    font-size: 1.8rem;
    font-family: $fontSourceSansPro;
    font-weight: 600;
    letter-spacing: (0.2rem/10);
    line-height: (32/18);
    color: $colorBlack70;
    text-decoration: underline;
}

%button-titles-big {
    font-size: 1.8rem;
    font-family: $fontSwedenSans;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: (23/18);
    color: $colorBlack70;
    text-transform: uppercase;
}

%button-titles {
    font-size: 1.4rem;
    font-family: $fontSwedenSans;
    letter-spacing: (0.8rem/10);
    line-height: (20/14);
    color: $colorBlack70;
    text-transform: uppercase;

    @include media(l) {
        font-size: 1.6rem;
        font-family: $fontSwedenSans;
        line-height: (20/16);
    }
}

%body-copy-small {
    font-size: 1.6rem;
    font-family: $fontSourceSansPro;
    line-height: (24/16);
    letter-spacing: (0.4rem/10);
    color: $colorBlack70;
}

%standard-link-small {
    font-size: 1.6rem;
    font-family: $fontSourceSansPro;
    letter-spacing: (0.4rem/10);
    line-height: (24/16);
    color: $colorBlack;
    text-decoration: underline;
}

%button-titles-small {
    font-size: 1.4rem;
    font-family: $fontSourceSansPro;
    font-weight: 600;
    line-height: (18/14);
    letter-spacing: (1rem/10);
    color: $colorBlack70;
    text-transform: uppercase;
}
