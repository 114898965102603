@import 'styles/includes.scss';

.RichText {
    $root: &;

    h1 {
        @extend %h1;
    }

    h2 {
        @extend %h2;
    }

    h3 {
        @extend %h3;
    }

    h4 {
        @extend %h4;

        + ul,
        + ol {
            padding-top: 16px;
            margin-bottom: 40px;
            border-top: 2px solid rgba($colorBlack, 0.12);
        }
    }

    h5 {
        @extend %h5;
    }

    h2,
    h3,
    h4,
    h5 {
        scroll-margin-top: 2em;

        a {
            text-decoration: none;
            border: none;
        }

        + ul,
        + ol {
            margin-top: 8px;
        }
    }

    a {
        color: $colorBlack70;
        text-decoration: none;
        scroll-margin-top: 2em;
        border-bottom: 1px solid #000;
        transition: color 200ms ease-out;

        &:hover {
            color: #000;
        }

        // Add external link styling if href containes visitsweden
        &:not([href*='visitsweden']),
        &[href*='corporate.visitsweden'],
        &[href*='traveltrade.visitsweden'] {
            line-height: 1.1;

            &::after {
                content: '';
                display: inline-block;
                min-width: 16px;
                min-height: 16px;
                margin-left: 3px;
                margin-right: 1px;
                background-image: url(#{$basepath}svg/icon-external-link.svg);
                background-repeat: no-repeat;
                background-size: contain;
                position: relative;
                bottom: -1.5px;
            }
        }
        // Remove external link styling if href starts with #
        &[href^='\#'] {
            padding-right: 0;

            &::after {
                display: none;
            }
        }
    }

    &[href*='document-proxy'] {
        &::after {
            background-image: url(#{$basepath}svg/icon-download.svg);
        }
    }

    p {
        @extend %body-copy;
        margin-bottom: 20px;
    }

    ul,
    ol {
        margin-bottom: 20px;
    }

    li {
        @extend %body-copy-small;

        color: inherit;
        padding-left: 20px;
        position: relative;
        margin-bottom: 8px;
    }

    ol {
        list-style-position: inside;
        list-style-type: decimal;
        text-indent: -20px;
    }

    ol li {
        &::marker {
            color: $colorBlue;
            font-weight: bold;
            margin-right: 12px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    ul li {
        &:before {
            content: '';
            display: block;
            height: 6px;
            width: 6px;
            position: absolute;
            top: 8px;
            left: 0;
            background-color: $colorBlue;
        }
    }

    strong,
    b {
        font-weight: bold;
    }
    em,
    i {
        font-style: italic;
    }

    &--WhiteOnBlue {
        a {
            color: #fff;
            border-color: #fff;

            &::after {
                background-image: url(#{$basepath}svg/icon-external-link-white.svg) !important;
            }

            &[href*='document-proxy']::after {
                background-image: url(#{$basepath}svg/icon-download-white.svg) !important;
            }
        }

        ul,
        ol {
            border-color: rgba(white, 0.12) !important;
        }

        ul {
            li {
                &::before {
                    background-color: white;
                }
            }
        }

        ol {
            li {
                &::marker {
                    color: white;
                }
            }
        }
    }

    :global(.responsive-object) {
        height: 0;
        position: relative;

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
